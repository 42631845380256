/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Footer } from 'flowbite-react';
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter,BsLinkedin,BsYoutube,BsSkype,BsPinterest,BsTelegram } from 'react-icons/bs';

function Footersection() {
  return (
   <>
 
    <Footer className='bg-green-900 text-white bg-opacity-80' >
      <div className="text-white w-full">
        <div className="grid w-full grid-cols-2 gap-8 px-6 py-8 md:grid-cols-4">
          <div>
            <Footer.Title className="text-white" title="Organisation" />
            <Footer.LinkGroup col>
              <Footer.Link className="text-white" href="/About">About</Footer.Link>
              <Footer.Link className="text-white" href="/Jobs">Careers</Footer.Link>
             
              <Footer.Link className="text-white" href="/Blog">Blog</Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className="text-white" title="help center" />
            <Footer.LinkGroup col>
              <Footer.Link className="text-white" href="mailto:aasthamedihelpmail@gmail.com">Support </Footer.Link> 
              <Footer.Link className="text-white" href="/Contact">Contact Us</Footer.Link>
              <Footer.Link className="text-white" href="mailto:aasthamedihelpmail@gmail.com" > Mail </Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className="text-white"  title="legal" />
            <Footer.LinkGroup col>
              <Footer.Link className="text-white" href="/Privacy">Privacy Policy</Footer.Link> 
              <Footer.Link className="text-white" href="/Terms">Terms &amp; Conditions</Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className="text-white"  title="Quick Links  " />
            <Footer.LinkGroup col>
              
              <Footer.Link className="text-white" href="/Doctorbooking"> Doctor's Booking </Footer.Link>  
              <Footer.Link className="text-white" href="/Quickbooking"> Quick Booking </Footer.Link>   
            </Footer.LinkGroup>
          </div>
        </div>
        <div className="w-full bg-green-300 bg-opacity-100 px-4 py-6 sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright className='text-black' href="mailto:aasthamedihelpmail@gmail.com"  target="_blank" by="AasthaMediHelp " year={2024} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon className='text-green-800' href="https://www.facebook.com/aasthamedihelpapp/" icon={BsFacebook} />
            <Footer.Icon className='text-green-800' href="https://www.instagram.com/aasthamedihelpapp/" icon={BsInstagram} />
            <Footer.Icon className='text-green-800' href="https://twitter.com/aasthamedihelpapp" icon={BsTwitter} />
            <Footer.Icon className='text-green-800' href="https://www.youtube.com/@aasthamedihelp" icon={BsYoutube} />
            <Footer.Icon className='text-green-800' href="https://in.linkedin.com/company/aasthamedihelpapp" icon={BsLinkedin} />
            <Footer.Icon className='text-green-800' href="https://t.me/aasthamedihelpapp" icon={BsTelegram} />
            <Footer.Icon className='text-green-800' href="https://in.pinterest.com/aasthamedihelpapp/" icon={BsPinterest} />
           
          </div>
        </div>
      </div>
    </Footer>
  

   </>
  )
}

export default Footersection