/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../Home/Navbarsection'
import Footersection from '../Home/Footersection'
import ReactPlayer from 'react-player' 

export default function Blog1() {
  return (
   <>
   <Navbarsection/>
   <div class="p-6  text-base">
 
 
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
          <h1  className="mx-auto text-left  font-bold lg:text-[18px]  sm:text-3xl justify-self-auto " >  What is  "AasthaMediHelp"    ? </h1>
 <br></br>
 
          
            <p className="text-base text-gray-700 md:text-lg">AasthaMediHelp is healthcare center when u get all kind of services including Medical Consulting , Enquiry , Booking , Etc. 
<br></br> 

            </p>
          </div>
          
        </div>
        <div>
        <div className='mr-3 player-wrapper'>
        <div>
          <img
            className="object-contain w-full h-56 rounded shadow-lg sm:h-96"
            src="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/Banners/Blog/blog1.png?updatedAt=1709811226144"
            alt=""
          />
        </div>
        </div>
 
        </div>
      </div>
    </div>
 
   <Footersection/>
   </>
  )
}
