/* eslint-disable no-unused-vars */
import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react"; 
 
export default function All_portfolio_hickter() {
  return (
   <>
   <section>
    <h2 className="w-1/1 p-5 mx-auto text-center font-bold text-[18px]"> Our Portfolio - Works </h2>
 
<div className="mt-[-50px] px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className="mt-0 w-96">
         <CardHeader color="blue-gray" className="relative h-56">
           <img
             src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706517647/Hickter%20Files/Portfolio/14.webp"
             alt="New Vatika | Clothing Store Brand 
             "
           />
         </CardHeader>
   
         <CardBody>
           <Typography variant="h5" color="blue-gray" className="mb-2">
           New Vatika | Clothing Store Brand 
           </Typography>
           <Typography>
           Clothing brand stores offer a unique shopping experience that is unlike any other. These stores typically sell a variety of clothing items from a single brand.
            
            </Typography>
         </CardBody>
   
         <div className="mb-[6px] ml-5 group inline-flex flex-wrap items-center  gap-7"> 
      
               <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-php"></i>
               </span>
          
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-wordpress"></i>
               </span>
         
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-html5"></i>
               </span>
            
             
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-css3"></i>
               </span>
            
           </div>
         <CardFooter className="pt-0">
          <a href="https://newvatika.in/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
         </CardFooter>
       </Card>
        </div>
        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706421464/Hickter%20Files/Portfolio/Luxuery-Perfume.png"
          alt="Luxury Perfume | Brand " />
      </CardHeader>

      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Luxury Perfume | Brand  
        </Typography>
        <Typography>
        This is the best place to buy perfumes and colognes online. We offer a wide selection of fragrances from top brands, including Chanel, Dior, Gucci.
group40         
         </Typography>
      </CardBody>

      <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-php"></i>
            </span>   
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-laravel"></i>
            </span> 

          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-html5"></i>
            </span> 

          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-css3"></i>
            </span> 

        </div>
      <CardFooter className="mt-0">
       <a href="https://luxuryperfume.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
        </div>
        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706421479/Hickter%20Files/Portfolio/Auragoa.png"
          alt="Auragoa | SPA & Living" />
      </CardHeader>

      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Auragoa | SPA & Living 
        </Typography>
        <Typography>

        This wellness oasis is an hour and a half’s drive from Goa International Airport.  Arambol beach, also very close to the property options Available .  
         </Typography>
      </CardBody>

      <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center gap-7">  
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-wordpress"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-css3"></i>
            </span>
         
        </div>
        <CardFooter className="pt-0">
       <a href="https://auragoa.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website
        <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
        </div>
      </div>
    </div>










    <div className="mt-[-50px] px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706421517/Hickter%20Files/Portfolio/voxtron.png"
          alt="Voxtron | Digital Solution

          "
        />
      </CardHeader>

      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Voxtron | Digital Solution 
        </Typography>
        <Typography>
        Application development company with 20+ years of experience, we build highly secure, scalable and robust applications for global businesses.  
         
         </Typography>
      </CardBody>

      <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fa-brands fa-js"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-wordpress"></i>
            </span>
         
        </div>
        <CardFooter className="pt-0">
       <a href="https://www.voxtronme.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
        </div>
        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706421496/Hickter%20Files/Portfolio/Isyx-Tech.png"
          alt="ISYX Tech – Tech Solution 
          "
        />
      </CardHeader>

      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
        ISYX Tech – Tech Solution
  
        </Typography>
        <Typography>
        The company was founded in 2011 . 10+ Global Offices, we help businesses to  impact on their customers by designing, implementing and managing infrastructure. 
         
         </Typography>
      </CardBody>

      <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-wordpress"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-css3"></i>
            </span>
         
        </div>
        <CardFooter className="pt-0">
       <a href="https://isyxtech.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
        </div>
        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706465799/Hickter%20Files/Portfolio/Fiji-Water.png"
          alt="Fiji Water – Brand Site 
          "
        />
      </CardHeader>

      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Fiji Water – Brand Site 
 
        </Typography>
        <Typography>
        FIJI Water has been committed to doing business responsibly and seeking opportunities to make a difference. Local communities, while focusing on meaningful issues. 
         
         </Typography>
      </CardBody>

      <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-laravel"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fa-brands fa-js"></i>
            </span>
         
        </div>
        <CardFooter className="pt-0">
       <a href="https://www.fijiwater.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
        </div>
      </div>
    </div>
{/* End 2nd Section  started  */}


{/* started  2nd Section    */}


<div className="mt-[-50px] px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
         <CardHeader color="blue-gray" className="relative h-56">
           <img
             src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706465849/Hickter%20Files/Portfolio/13.webp"
             alt="TheKalpanik | Blogs For Lifestyle 
             "
           />
         </CardHeader>
   
         <CardBody>
           <Typography variant="h5" color="blue-gray" className="mb-2">
           TheKalpanik | Blogs For Lifestyle
 
           </Typography>
           <Typography>
           Lifestyle blogs cover a wide range of topics, from fashion and beauty to food and travel.It’s known for its beautiful photography and relatable content.
            
            </Typography>
         </CardBody>
   
         <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
      
               <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-php"></i>
               </span>
          
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-wordpress"></i>
               </span>
         
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-html5"></i>
               </span>
            
             
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-css3"></i>
               </span>
            
           </div>
         <CardFooter className="pt-0">
          <a href="https://www.thekalpanik.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
         </CardFooter>
       </Card>
        </div>



        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706465848/Hickter%20Files/Portfolio/11.webp"
          alt="Hotel Prince | Hotel & Room Booking 
          "
        />
      </CardHeader>

      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
         Hotel & Room Booking 
        </Typography>
        <Typography>
        This hotel in Odisha offer a number of benefits to customers. They are affordable, convenient, safe, and supportive. They also offer a number of specific benefits.
         
         </Typography>
      </CardBody>

      <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-laravel"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-css3"></i>
            </span>
         
        </div>
      <CardFooter className="pt-0">
       <a href="https://m-hotelprince.web.app/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
        </div>



        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706465847/Hickter%20Files/Portfolio/8.webp"
          alt="SKJ Bill | Accounting 
          "
        />
      </CardHeader>

      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
        SKJ Bill | Accounting 
        </Typography>
        <Typography>
        Accounting apps offer a number of benefits to businesses and individuals. They can help you to improve the accuracy and efficiency of your accounting, make it easier.
         
         </Typography>
      </CardBody>

      <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-wordpress"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-css3"></i>
            </span>
         
        </div>
        <CardFooter className="pt-0">
       <a href="https://skjbill.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
        </div>
      </div>
    </div>
{/* End 2nd Section  */}

{/* started  2nd Section    */}


<div className="mt-[-50px] px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
         <CardHeader color="blue-gray" className="relative h-56">
           <img
             src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706465847/Hickter%20Files/Portfolio/7.webp"
             alt="Money Vision | Mutual Fund

             "
           />
         </CardHeader>
   
         <CardBody>
           <Typography variant="h5" color="blue-gray" className="mb-2">
           Money Vision | Mutual Fund 
           </Typography>
           <Typography>
           Mutual fund apps offer a number of benefits to investors of all experience levels. They provide a convenient, simple, and affordable way to invest
            
            </Typography>
         </CardBody>
   
         <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
      
               <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-php"></i>
               </span>
          
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-wordpress"></i>
               </span>
         
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-html5"></i>
               </span>
            
             
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-css3"></i>
               </span>
            
           </div>
         <CardFooter className="pt-0">
<tr>
  <td>  <a href="https://moneyvision.in/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  <td> <a href="https://play.google.com/store/apps/details?id=com.moneyvisionappofficial.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
</tr>
        
        
         </CardFooter>
       </Card>
        </div>



        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706465847/Hickter%20Files/Portfolio/5.webp"
          alt="MyHome | Real-Estate Booking

          "
        />
      </CardHeader>

      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
       Real-Estate Booking 
        </Typography>
        <Typography>
        Real estate apps offer a number of benefits to both buyers and sellers. For buyers, real estate apps can make the home buying process more convenient, personalized, and transparent.  
         </Typography>
      </CardBody>

      <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-laravel"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-css3"></i>
            </span>
         
        </div>
      <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://bookmyfirsthome.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
</tr>
      </CardFooter>
    </Card>
        </div>



        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706465846/Hickter%20Files/Portfolio/6.webp"
          alt="Dirghayu | Ayurveda Medical Healthcare 
          "
        />
      </CardHeader>

      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
         Ayurveda Medical Healthcare 
        </Typography>
        <Typography>
        Medical treatment is any intervention that is used to diagnose, prevent, or treat a disease or injury. It can include a wide range of interventions.
         
         </Typography>
      </CardBody>

      <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-wordpress"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-css3"></i>
            </span>
         
        </div>
        <CardFooter className="pt-0">
        <tr>
  <td>  <a href="https://dirghayuapp.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  <td> <a href="https://www.dirghayuapp.com/appfile/SELLER.apks" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
</tr></CardFooter>
    </Card>
        </div>
      </div>
    </div>
{/* End 2nd Section  */}



{/* started  2nd Section    */}


<div className="mt-[-50px] px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
         <CardHeader color="blue-gray" className="relative h-56">
           <img
             src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706465845/Hickter%20Files/Portfolio/1.webp"
             alt="Canas Day SPA 
             "
           />
         </CardHeader>
   
         <CardBody>
           <Typography variant="h5" color="blue-gray" className="mb-2">
           Canas Day SPA 
           </Typography>
           <Typography>
           SPA relaxation can offer a number of benefits to your physical and mental health, and to your overall well-being. 
            
            </Typography>
         </CardBody>
   
         <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
      
               <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-php"></i>
               </span>
          
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-wordpress"></i>
               </span>
         
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-html5"></i>
               </span>
            
             
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-css3"></i>
               </span>
            
           </div>
         <CardFooter className="pt-0">
          <a href="https://www.canasdayspa.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
         </CardFooter>
       </Card>
        </div>



        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706465846/Hickter%20Files/Portfolio/2.webp"
          alt="The Pench International 
          "
        />
      </CardHeader>

      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
        The Pench International
 
        </Typography>
        <Typography>
        There are many benefits to booking a resort and water park in advance. You can save money, get better availability.
         
         </Typography>
      </CardBody>

      <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-wordpress"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-css3"></i>
            </span>
         
        </div>
      <CardFooter className="pt-0">
       <a href="https://thepenchinternational.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
        </div>

        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706465846/Hickter%20Files/Portfolio/3.webp"
          alt="Hari Om Metallics  
          "
        />
      </CardHeader>

      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Hari Om Metallics 
        </Typography>
        <Typography>
        Manufacturing of roof tiles has a number of benefits, including high demand, profitability, job creation, and economic benefits.
         
         </Typography>
      </CardBody>

      <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-wordpress"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-css3"></i>
            </span>
         
        </div>
        <CardFooter className="pt-0">
       <a href="https://www.hariommetallics.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
        </div>
      </div>
    </div>
{/* End 2nd Section  */}



{/* started  2nd Section    */}


<div className="mt-[-50px] px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
         <CardHeader color="blue-gray" className="relative h-56">
           <img
             src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706465846/Hickter%20Files/Portfolio/4.webp"
             alt="Kansal Store 
             "
           />
         </CardHeader>
   
         <CardBody>
           <Typography variant="h5" color="blue-gray" className="mb-2">
           Kansal Store 
           </Typography>
           <Typography>
           Bike autoparts hardware offers a number of benefits to cyclists of all levels of experience. It can improve the durability, performance, safety, and style of your bike. 
            
            </Typography>
         </CardBody>
   
         <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
      
               <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-php"></i>
               </span>
          
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-wordpress"></i>
               </span>
         
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-html5"></i>
               </span>
            
             
             <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
               <i style={{fontSize:20}} className="fab fa-css3"></i>
               </span>
            
           </div>
         <CardFooter className="pt-0">
          <a href="https://kansal.co.in/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
         </CardFooter>
       </Card>
        </div>



        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706465847/Hickter%20Files/Portfolio/9.webp"
          alt=" Solar Electricity Solution
          "
        />
      </CardHeader>

      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Solar Electricity Solution 
        </Typography>
        <Typography>
        solar renewable energy companies offer a number of benefits to businesses and homeowners.  They also offer a variety of specific benefits.
         
         </Typography>
      </CardBody>

      <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-laravel"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
          <i style={{fontSize:20}} className="fab fa-css3"></i>
            </span>
         
        </div>
      <CardFooter className="pt-0">
       <a href="https://www.fraternitytechnocrats.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
        </div>



        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        <Card className=" mt-0 w-96">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706465848/Hickter%20Files/Portfolio/12.webp"
          alt="Aasthamedihelp | Mediacal Consultancy 
          "/>
      </CardHeader>

      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Aasthamedihelp | Mediacal Consultancy 
        </Typography>
        <Typography>
        variety of services, including MBBS admission guidance, student visa assistance, and travel arrangements.  
         
         </Typography>
      </CardBody>

      <div className="group mb-[6px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-java"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:20}} className="fab fa-css3"></i>
            </span>
         
        </div>
        <CardFooter className="pt-0">
       <a href="https://aasthamedihelp.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
        </div>
      </div>
    </div>
{/* End   */}
 

</section> 
 
    {/* Sample Single grid Card Portfolio    */}

{/*   <div className="mt-[-50px] px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        
        </div> 
        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
        
        </div> 
        <div className="overflow-hidden transition-shadow duration-200 bg-white rounded shadow-sm">
         
        </div>
      </div>
    </div>
  */}
     
   </>
  )
}
