/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Avatar,
  Tooltip,
} from "@material-tailwind/react";
export default function Booking() {
  return (
   <><Navbarsection /> 

<section className="text-gray-600 body-font">
  <div className="container px-5 py-6 mx-auto">
    <div className="flex flex-wrap -mx-4 -my-8">
      <div className="py-8 px-4 lg:w-1/2">
        <div className="h-full flex items-start">
        <Card className="max-w-[24rem] overflow-hidden">
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 rounded-none"
      >
        <img
          src="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/Banners/doctor-booking-aa.png?updatedAt=1709825531730"
          alt="ui/ux review check"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h4" color="blue-gray">
         Doctor's Booking 
        </Typography>
        <Typography variant="lead" color="gray" className="mt-3 font-normal">
          Booking Online Doctor at "AasthaMediHelp" 
        </Typography>
      </CardBody>
      <CardFooter className="flex items-center justify-between">
         
      <a
  className="mt-[-33px] inline-flex items-center gap-2 rounded border border-indigo-600 bg-green-600 px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
  href="/Doctorbooking"
>
  <span className="text-sm font-medium"> Book Now </span>

  <svg
    className="h-5 w-5 rtl:rotate-180"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </svg>
</a>
      </CardFooter>
    </Card>
        </div>
      </div>
       
      <div className="py-8 px-4 lg:w-1/2">
        <div className="h-full flex items-start">
        <Card className="max-w-[24rem] overflow-hidden">
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 rounded-none"
      >
        <img
          src="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/Banners/quick-booking-aa.png?updatedAt=1709825532389"
          alt="ui/ux review check"
        />
      </CardHeader>
      <CardBody>
        <Typography variant="h4" color="blue-gray">
         Quick Booking 
        </Typography>
        <Typography variant="lead" color="gray" className="mt-3 font-normal">
         Book all kinds of Services at "AasthaMediHelp". 
        </Typography>
      </CardBody>
      <CardFooter className="flex items-center justify-between">
      <a
  className="mt-[-33px] inline-flex items-center gap-2 rounded border border-indigo-600 bg-green-600 px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
  href="/Quickbooking"
>
  <span className="text-sm font-medium"> Book Now </span>

  <svg
    className="h-5 w-5 rtl:rotate-180"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </svg>
</a>
       </CardFooter>
    </Card>
        </div>
      </div>
    </div>
  </div>
</section>

   <Footersection />
   
   </>
  )
}
