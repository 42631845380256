/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
import { Accordion } from 'flowbite-react';

export default function Faqsection() {
  return (
   <>
   <br></br>
   <div class="mx-auto">
      <div  class="mb-[2px] text-gray-600 body-font  ">
      <h3 class="text-center text-2xl font-bold sm:text-3xl"> FAQ  </h3>
        </div>
      </div>

  <Accordion className="p-4" >
      <Accordion.Panel >
        <Accordion.Title className=" text-green-600 font-bold" >What is "AasthaMediHelp" ?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
      AasthaMediHelp is healthcare center when u get all kind of services including Medical Consulting , Enquiry , Booking , Etc.  </p>   
           
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title className=" text-green-600 font-bold" > Why to Choose "AasthaMediHelp" ?  </Accordion.Title>
        <Accordion.Content>
          <p className="mb-1 text-gray-500 dark:text-gray-400">
          
<b>  Expertise and Objectivity:  </b>Medical consultants bring in specialized knowledge and experience that a facility might not have in-house. They can provide a fresh perspective and identify areas for improvement that staff might miss.   
<br></br>
<b> Efficiency and Cost-Effectiveness:</b> Consultants can help facilities streamline operations, reduce waste, and identify cost-saving measures. They can recommend ways to improve workflow, staffing, and resource allocation.
<br></br>
<b> Implementing New Technologies:  </b>The healthcare field is constantly evolving with new technologies. Consultants can help facilities stay up-to-date and integrate new technologies effectively into their practice.
<br></br>
<b> Regulatory Compliance: </b> The healthcare industry has complex regulations. Consultants can ensure that facilities are adhering to all relevant regulations and standards.
<br></br>
<b> Improving Patient Satisfaction:</b>  Consultants can help facilities identify ways to improve the patient experience, such as reducing wait times or enhancing communication.
<br></br>
<b> Specific Challenges: </b> Sometimes a facility might face a specific challenge, such as a decline in patient numbers or a quality control issue. Consultants can be brought in to diagnose the problem and recommend solutions.
<br></br>
<b> Strategic Planning and Growth: </b> Consultants can assist with long-term planning and help facilities develop strategies for growth and expansion.
          </p>
        
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>

        <Accordion.Title className=" text-green-600 font-bold" >  How to Contact "AasthaMediHelp" For Support  ?  </Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          Just   <a className='font-bold text-[16px] text-pink-700' href='/Contact'>
          Contact Here  </a>Fill The Form & Get Update Soon For Process. 
         
          </p>
             
        </Accordion.Content>
      </Accordion.Panel>

      <Accordion.Panel>

<Accordion.Title className=" text-green-600 font-bold" > How To Book Doctor & Medical Services on "AasthaMediHelp" ?  </Accordion.Title>
<Accordion.Content>
<p className="mb-2 text-gray-500 dark:text-gray-400">
          Just   <a className='font-bold text-[16px] text-pink-700' href='/Booking'>
          Go To Booking Here  </a>Fill The Form & Get Update Soon For Process. 
         
          </p>
    
   
</Accordion.Content>
</Accordion.Panel>

 
    </Accordion>
   </>
  )
}
 