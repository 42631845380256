 /* eslint-disable no-unused-vars */
import React from 'react'
 import Cardsk from '../Components/Cardsk'
import Skeletonui from '../Components/Skeletonui'
import { useState, useEffect } from "react";

import Navbarsection from "../views/Home/Navbarsection" 
  import Footersection from "../views/Home/Footersection"
  import Iframe from 'react-iframe'


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);
  
 
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <><Navbarsection />
    

        <div className="">
         
       
      <div className="hidden md:block mt-4 rounded-lg p-[1px] shadow-lg">

       
      {loading ? <Skeletonui />  :  <Iframe url="https://img.freepik.com/free-vector/abstract-coming-soon-halftone-style-background-design_1017-27282.jpg?size=626&ext=jpg&ga=GA1.1.87170709.1707955200&semt=ais"
         width="100%"
         height="700px" 
         display="block"
         position=""/>   }

        {/*  Dextop Only  */}
        
 
       </div>
{/* Mobile Only  */}
       <div className="block lg:hidden mt-4 rounded-lg p-[1px] shadow-lg"> 
        
        
      
     {loading ? <Skeletonui /> :     <Iframe url="https://apply.hickter.in/jobs_apply/"
         width="100%"
         height="800px" 
         display="block"
         position=""/>}
  
 
       </div>
         
        </div>
     
    <Footersection />
    
    
    
    
    
    </>
  );
}

export default App;
 





 