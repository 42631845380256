/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-undef */
import { Card } from 'flowbite-react';
  
export default function Feature() {
  return (
   <> 
 
 <section class="text-gray-600 bg-green-400 body-font">
<br></br>  <div class="container px-5 py-2 mx-auto">
    <div class="flex flex-wrap -m-4 text-center">
      <div class="p-4 sm:w-1/4 w-1/2">
      
     <a href='/Quickbooking' > <Card className=" " imgSrc="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/services/Medical%20Consultations.png?updatedAt=1709804491921" >
           
          <h6 className="mb-2 text-[8px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:20}} className="text-green-600  mt-[2px] fa-solid fa-notes-medical"></i> <br></br> </span>  Medical Consultations 
          </h6>  
          </Card></a> 
      </div>

      <div class="p-4 sm:w-1/4 w-1/2">
      
      
     <a href='/Quickbooking' >
     <Card className=" " imgSrc="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/services/Home%20Diagnostic%20%20Services.png?updatedAt=1709804481523" >
            
          <h6 className="mb-2 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:20}} className="text-green-600  mt-[2px] fa-solid fa-person-dots-from-line"></i> <br></br> </span>  Home Diagnostic   
          </h6>  
          </Card></a> 
      </div>
      
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='/Quickbooking' > <Card className=" " imgSrc="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/services/Injection%20&%20Vaccine%20Services.png?updatedAt=1709804478819" >
          
          <h6 className="mb-2 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-green-600  mt-[2px] fa-solid fa-suitcase-medical "></i> <br></br> </span>    Injection & Vaccine  
          </h6> 
          </Card></a>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='/Quickbooking'  >  <Card className=" " imgSrc="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/services/Medicines%20Delivery.png?updatedAt=1709804468698" >
           
          <h6 className="mb-2 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-green-600  mt-[2px] fa-solid fa-bicycle"></i> <br></br> </span>   Medicines Delivery
          </h6>
          
          </Card> </a><br></br>
      </div>
    </div>
    <br></br>  </div>

 
  <div class="container px-5 py-2 mx-auto">
    <div class="flex flex-wrap -m-4 text-center">
      <div class="p-4 sm:w-1/4 w-1/2">

      <a href='/Quickbooking' > 
       <Card className="" imgSrc="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/services/Emergency%20Ambulance.png?updatedAt=1709804499003" >
         
          <h6 className="mb-1 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-green-600  mt-[1px] fa-solid fa-truck-medical "></i> <br></br> </span>  Emergency Ambulance
          </h6>
           
          </Card></a>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='/Quickbooking'  >    <Card className=" " imgSrc="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/services/Home%20Nursing%20Services.png?updatedAt=1709804495550" >
          
          <h6 className="mb-0 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-green-600  mt-[1px] fa-solid fa-user-nurse "></i> <br></br> </span>   Home Nursing Services
          </h6>
         
          </Card></a>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='/Quickbooking'  >   <Card className=" " imgSrc="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/services/Pharmacist%20Appointments.png?updatedAt=1709804495972" >
           
          <h6 className="mb-0 text-[11px] lg:text-[10px]  font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-green-600  mt-[0px] fa-solid fa-user-doctor "></i> <br></br> </span>  OldAged Care
          </h6> 
          </Card></a>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='/Quickbooking'  >   <Card className=" " imgSrc="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/services/Women%20Healthcare.png" >
          
          <h6 className="mb-0 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-green-600  mt-[0px] fa-solid fa-person"></i> <br></br> </span>  Women Healthcare 
          </h6>
          
          </Card></a>
      </div>
    </div><br></br>
  </div> 
</section>

 
  </>
  )
}
  