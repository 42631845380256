/* eslint-disable react/no-unescaped-entities */
import Skeletonui from '../Components/Skeletonui'
import { useState, useEffect } from "react";
/* eslint-disable no-unused-vars */
import Iframe from 'react-iframe' 
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
 
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'
 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'font-awesome/css/font-awesome.min.css'; 


   
export default function Digital_marketing_hickter() {
  const [size, setSize] = React.useState(null);
 
  const handleOpen = (value) => setSize(value);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4300);
  }, []);
  return (
   <>
   <Navbarsection/>
   {/*    Start Modal Section Section  */}

   <Dialog
        open={
          size === "xs" ||
          size === "sm" ||
          size === "md" ||
          size === "lg" ||
          size === "xl" ||
          size === "xxl"
        }
        size={size || "md"}
        handler={handleOpen}
      >
        <DialogHeader>  <h1  className="mb-4 mx-auto text-center border  font-bold text-[18px] justify-self-auto "> Digital Marketing | Hickter </h1><DialogFooter>
           
           <Button className='mt-[-20px] bg-red-400'
             onClick={() => handleOpen(null)}
           >
             <span> <i style={{fontSize:14}} className="text-white  fa-solid fa-xmark "></i> Close </span>
           </Button>
         </DialogFooter> </DialogHeader>


        <DialogBody>
        {loading ? <Skeletonui /> :  <Iframe url="https://apply.hickter.in/digital_marketing__apply/"
         width="100%"
         height="490px" 
         display="block"
         position="relative"/>}
       
        </DialogBody>
   </Dialog>
 
  {/*    End Modal Section Section  */}

   <div className="px-4 py-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-6 lg:py-15">
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
         
          <div className="max-w-xl mb-1">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Digital Marketing  
            <br></br>  <span className="text-green-500">
              At Hickter
              </span>
            </h2>
            <Typography>
        ✔ Now Grow Your Business Sales & Revenue By Paid Ads and Marketing to reach Potential Customer's By Medium you want. .  
        </Typography> 
          </div>
           
          <CardBody className="bg-white" > 
          <Typography
          variant="h1"
          color="black"
          className="mt-0 flex justify-center gap-1 text-7xl font-normal"><span className="mb-0 text-orange-400 font-bold self-end text-[14px]"> Start <br></br>From  </span> 
          <span className="mt-0 text-[32px]">₹5,000 </span>{""}
          <span className="self-end text-[14px]"> / Month </span>  
        </Typography> <br></br>
       
        <Typography>
        Get These Services 
        </Typography>
 
        <Typography className="text-left">
        <div className="flex gap-1">
 
<div className="bg-teal-900 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="ml-3 text-center h-10 w-9"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706264299/Hickter%20Files/Icons/iconsfiles/Logo_Icons__6_-removebg-preview.png"
          alt=""
        /> <p className="text-[13px]" > SEO Marketing  </p>
      </div> 

<div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-center p-1 h-10 w-14"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706264382/Hickter%20Files/Icons/iconsfiles/social-media_2.png"
          alt=""
        /> <p className="text-[13px]" > Social Ads   </p>
      </div> 

<div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-center h-10 w-13"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706264568/Hickter%20Files/Icons/iconsfiles/meta.png"
          alt=""
        /> <p className="text-[13px]" > Meta Ads  </p>
      </div> 
 
      <div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10" >
      <img className="text-center p-2 h-10 w-14"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706264949/Hickter%20Files/Icons/iconsfiles/adwords.png"
          alt=""
        /> 
        <p className="text-[13px]" > Google Ads </p>
      </div> 
 
</div>
  
        </Typography>
  
      </CardBody>
          <div>
            <Button onClick={() => handleOpen("xxl")} variant="gradient" 
              aria-label=""
              className="ml-[21px] text-center flex items-center font-semibold transition-colors duration-200 bg-blue-600 text-white p-3 rounded hover:text-deep-purple-800"
            >
              <span className="cursor-pointer rounded-full  ">
            <i style={{fontSize:18}} className="fa-solid fa-check-double ml-4 p-2"></i>
            </span>  Submit Your Request
              <svg
                className="inline-block w-3 ml-2"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
              </svg>  
            </Button>
          </div>

        </div>
        <div className="flex items-center justify-center -mx-4 lg:pl-8">
          <div className="flex flex-col items-end px-3">
            <img
              className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
              src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706624039/Hickter%20Files/Banners/Blogpics/ab2.png?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
              alt=""
            />
            <img
              className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
              src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706621384/Hickter%20Files/Banners/Digitalmarketing/d1.png?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
              alt=""
            />
          </div>
          <div className="px-3">
            <img
              className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
              src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706624131/Hickter%20Files/Banners/Blogpics/Untitled_design_17.png?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500"
              alt=""
            /> 
          </div>
           
        </div>
      </div>
    </div>
   <Footersection/> 
   </>
  )
}
 