/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../Home/Navbarsection'
import Footersection from '../Home/Footersection'


export default function Blog3() {
  return (
   <>
   <Navbarsection/>
   <div class="p-6  text-base">
 
 
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-left">
          <div className="max-w-xl mb-6">
          <h1  className="mx-auto text-left  font-bold lg:text-[16px]  sm:text-3xl justify-self-auto " >  How To Contact "AasthaMediHelp" & Apply for Booking Online? </h1>
 <br></br>
 
          
            <p className="text-base text-gray-700 md:text-lg">
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Here's the details and links for Booking & Contact to "AasthaMediHelp":

<br>
</br><br></br>

<b> For Booking </b>


<p className="mb-2 text-gray-500 dark:text-gray-400">
          Just   <a className='font-bold text-[16px] text-pink-700' href='/Booking'>
          Go To Booking Here  </a>Fill The Form & Get Update Soon For Process. 
         
          </p>

  
          </p>
          <b> For Contact </b>
<br></br>


          <p className="mb-2 text-gray-500 dark:text-gray-400">
          Just   <a className='font-bold text-[16px] text-pink-700' href='/Contact'>
          Contact Here  </a>Fill The Form & Get Update Soon For Process. 
         
          </p>
          
 
   </p>
          </div>
          
        </div>
        <div>
        <div className='player-wrapper'>
        <img src="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/Banners/Blog/blog2.png?updatedAt=1709811226135" className="h-210 w-full 
    rounded --300" alt="" /> 
        </div>
 
        </div>
      </div>
    </div>
 
   <Footersection/>
   </>
  )
}
