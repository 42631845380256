/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../Home/Navbarsection'
import Footersection from '../Home/Footersection'


export default function Blog2() {
  return (
   <>
   <Navbarsection/>
   <div class="p-6  text-base">
 
 
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
          <h1  className="mx-auto text-left  font-bold lg:text-[16px]  sm:text-3xl justify-self-auto " > Why to Choose "AasthaMediHelp"  ? </h1>
 <br></br>
 
          
            <p className="text-base text-gray-700 md:text-lg">
            <p className="mb-1 text-gray-500 dark:text-gray-400">
          
<b>  Expertise and Objectivity:  </b>Medical consultants bring in specialized knowledge and experience that a facility might not have in-house. They can provide a fresh perspective and identify areas for improvement that staff might miss.   
<br></br>
<b> Efficiency and Cost-Effectiveness:</b> Consultants can help facilities streamline operations, reduce waste, and identify cost-saving measures. They can recommend ways to improve workflow, staffing, and resource allocation.
<br></br>
<b> Implementing New Technologies:  </b>The healthcare field is constantly evolving with new technologies. Consultants can help facilities stay up-to-date and integrate new technologies effectively into their practice. 
          </p>
  
 
     </p>
          </div>
          
        </div>
        <div>
        <div className='player-wrapper'>
        <img src="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/Banners/Blog/blog3.png?updatedAt=1709811226749" className="h-210 w-full 
    rounded --300" alt="" /> 
        </div>
 
        </div>
      </div>
    </div>
 
   <Footersection/>
   </>
  )
}
