/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-undef */
import { Card } from 'flowbite-react';
import Navbarsection from './Navbarsection';
import Footersection from './Footersection';

export default function Whyus() {
  return (
   <>
   <Navbarsection/>
 
   <section className="items-center flex flex-col" >
    
  <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-2"><h2 class="w-1/2 p-4 mx-auto text-center font-bold text-[20px]"> Why Choose Us </h2>
      <div className="grid gap-5 row-gap-5 sm:grid-cols-2 lg:grid-cols-4">


        <div className=" px-6 text-left sm:px-0">
        <Card className="hover:scale-[1.02] focus:scale-[1] hover:text-green-700 " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-left justify-left w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
</svg>


          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
          Expertise and Objectivity
          </h6>
          <div className="mb-2 text-gray-700">
          Medical consultants bring in specialized knowledge and experience that a facility might not have in-house.  
          </div>
          </Card>
        </div>

        <div className=" px-6 text-left sm:px-0">
        <Card className="hover:scale-[1.02] focus:scale-[1] hover:text-green-700 " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
</svg>


          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
          Implementing New Technologies: :
          </h6>
          <div className="mb-1 text-gray-700">
          The healthcare field is constantly evolving with new technologies. Consultants can help facilities stay up-to-date.
          </div>
          </Card>
        </div>


        <div className=" px-6 text-left sm:px-0">
        <Card className="hover:scale-[1.02] focus:scale-[1] hover:text-green-700 " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
</svg>


          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
          Regulatory Compliance: 
          </h6>
          <div className="mb-2 text-gray-700">
          The healthcare industry has complex regulations. Consultants that facilities are adhering to all relevant regulations and standards.
          </div>
          </Card>
        </div>



        <div className=" px-6 text-left sm:px-0">
        <Card className="hover:scale-[1.02] focus:scale-[1] hover:text-green-700 " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-left justify-left w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
</svg>


          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
          Strategic Planning and Growth:
          </h6>
          <div className="mb-2 text-gray-700">
          Consultants can assist with long-term planning and help facilities develop strategies for growth and expansion.
          </div>
          </Card>
        </div>


      </div>
    </div>
</section>

<Footersection/> 
  </>
  )
}
 