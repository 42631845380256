/* eslint-disable react-refresh/only-export-components */
/* eslint-disable no-unused-vars */
import Skeletonui from '../Components/Skeletonui'
import { useState, useEffect } from "react";
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
 import 'react-loading-skeleton/dist/skeleton.css'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import Iframe from 'react-iframe'
 
 import "react-multi-carousel/lib/styles.css";
 const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2000, min: 1000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 2000, min: 900 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items:1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
function CheckIcon() {
  const [size, setSize] = React.useState(null);
 
  const handleOpen = (value) => setSize(value);
 
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}

export default function Startup_plan_hickter() {
  const [size, setSize] = React.useState(null);
 
  const handleOpen = (value) => setSize(value);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);
  return (
    <>
    
    <Navbarsection/>
         {/*    Start Modal Section Section  */}

    <Dialog
        open={
          size === "xs" ||
          size === "sm" ||
          size === "md" ||
          size === "lg" ||
          size === "xl" ||
          size === "xxl"
        }
        size={size || "md"}
        handler={handleOpen}
      >
        <DialogHeader>  <h1  className="mb-4 mx-auto text-center border  font-bold text-[18px] justify-self-auto "> Startup Plan | Hickter </h1><DialogFooter>
           
           <Button className='mt-[-20px] bg-red-400'
             onClick={() => handleOpen(null)}
           >
             <span> <i style={{fontSize:14}} className="text-white  fa-solid fa-xmark "></i> Close </span>
           </Button>
         </DialogFooter> </DialogHeader>


        <DialogBody>
        <div className="flex justify-center items-center h-screen">
      {loading ? <Skeletonui /> : <Iframe url="https://apply.hickter.in/startup_apply/"
         width="100%"
         height="490px" 
         display="block"
         position="relative"/>}
    </div>

        
        
        </DialogBody>
   </Dialog>
 
  {/*    End Modal Section Section  */}

    <div className="p-1  text-base">
 
 <div className="relative  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-4 lg:px-8 lg:py-2">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 z-0 w-full h-full bg-gray-100 lg:w-3/4" />
      </div>
      <div className="relative">
        <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
 {/*    Images Section  */}
 <div className='hidden md:block'>
            <img
              className="object-contain mt-[54px] w-full h-56 rounded sm:h-96"
              src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706625514/Hickter%20Files/Banners/Plans/56437440664.png?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
              alt=""
            />
          </div>

{/*    Content  Section Dextop Only   */}
  

          <div className="hidden md:block gap-2 row-gap-5 md:grid-cols-1">
            <div className="relative">
         <Card  variant="gradient" className="bg-purple-900	
     mx-auto rounded p-[6px]">
         
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 mb-2 rounded-2 border-b border-white/10 pb-4 text-center"
      > 

      <tr>

        <td className='mr-4' >  <Typography
          variant="h1"
          color="white"
          className="mt-6 flex justify-center gap-1 text-7xl font-normal">
          <span className="mt-2 ml-5 text-[25px]"> ₹12,000 </span>{""}
          <span className="self-end text-[14px]"> / Yearly or Annually  </span> 

        </Typography>
        <br></br>  </td>

        <td>  <div className="text-center" class="flex gap-10">
 <br></br> 
 <p class="text-yellow-300 text-[30px] line-through ...">  ₹15,000</p>

 <br></br> 
<div
    class="relative grid items-center px-2 py-1 font-sans text-xs font-bold text-green-50 uppercase rounded-md select-none whitespace-nowrap bg-green-600">
    <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
      <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-400 content-['']"></span>
    </div> 
    <span class="ml-3 p-1 text-[12px]">20% Off </span> 

  </div>
 </div> 
        <br></br></td> 
      </tr>
         
   
      <div
     class="p-4 relative grid select-none items-center whitespace-nowrap rounded-lg bg-orange-600 py-2 px-1 font-sans text-xs uppercase text-white">
     <span class="text-[10px]"> * Payment 40% Advance & 60% After Project Completed .   </span>
   </div><br></br>
    
   <div
     class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-teal-300 py-2 px-1 font-sans text-xs uppercase text-black">
     <span class="">*  Free Support - 24x7  | 1 Year  </span>
   </div>
      </CardHeader>
      <CardBody className="p-0">

      <table class="w-full text-sm text-left rtl:text-right text-gray-500
 dark:text-gray-400">
        
        <tbody>
 {/*   1st  Section  */}
            <tr class="bg-transparent  dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                  <li className="flex items-center gap-2">
            <span className="rounded-full border border-white/20 bg-teal-400 p-1">
              <CheckIcon />
            </span>
            <Typography className="text-white text-[14px] font-normal">App Development <div
    class="text-[10px] relative grid select-none items-center whitespace-nowrap rounded-lg bg-white py-1 px-2 font-sans  uppercase text-black">
    <span className="text-[10px]"> <i style={{fontSize:15}} className="text-green-500 fa-brands fa-android "></i>  Android Only  </span>
  </div>

 </Typography>
          </li>
                  </Typography>
                </th>
                <td class="px-6 py-4">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                   <li className="flex items-center gap-2">
            <span className="rounded-full border border-white/20 bg-teal-400 p-1">
              <CheckIcon />
            </span>
            <Typography className="text-white text-[14px] mr-4 font-normal"> Website Development 
</Typography>
          </li>
                  </Typography>
                </td> 


                <td class="px-6 py-4">
                            <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    <li className="flex items-center gap-2">
            <span className="rounded-full border border-white/20 bg-teal-400 p-1">
              <CheckIcon />
            </span>
            <Typography className="text-white text-[14px]  font-normal">Digital Marketing 
</Typography>
          </li>
                  </Typography>
                </td> 
            </tr>

{/* End First Section  */}
{/*   2nd  Section  */}

            <tr class="bg-transparent  dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                  <li className="flex items-center gap-2">
            <span className="rounded-full border border-white/20 bg-teal-400 p-1">
              <CheckIcon />
            </span>
            <Typography className="text-white  text-[14px] font-normal">
            Banner & Video Ads    
            </Typography>
          </li>
                  </Typography>
                </th>
                <td class="px-6 py-4">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                  <li className="flex items-center gap-2">
            <span className="rounded-full border border-white/20 bg-teal-400 p-1">
              <CheckIcon />
            </span>
            <Typography className="text-white text-[14px] font-normal">
            Domain & Hosting   
            </Typography>
          </li>
                  </Typography>
                </td>
                <td class="px-6 py-4">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                  <li className="flex items-center gap-2">
            <span className="rounded-full border border-white/20 bg-red-400 p-1">
              <CheckIcon />
            </span>
            <Typography className="text-white text-[14px] font-normal line-through">
            Company Formation+ 
            </Typography>
          </li>
                  </Typography>
                </td>
                 
                
            </tr>
{/*   3rd  Section  */}
            <tr class="bg-transparent dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                  <li className="flex items-center gap-2">
            <span className="rounded-full border border-white/20 bg-teal-400 p-1">
              <CheckIcon />
            </span>
            <Typography className="text-white text-[14px] font-normal ">
            Upgrade Version+ 
            </Typography>
          </li>
                  </Typography>
                </th>
                <td class="px-6 py-4">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                  <li className="flex items-center gap-1">
            <span className="rounded-full border border-white/20 bg-red-400 p-1">
              <CheckIcon />
            </span>
            <Typography className="text-white text-[14px] font-normal line-through ">
            Full Stack Feature+ 
            </Typography>
          </li>
                  </Typography>
                </td>
                <td class="px-6 py-4">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                  <li className="flex items-center gap-1">
            <span className="rounded-full border border-white/20 bg-red-400 p-1">
              <CheckIcon />
            </span>
            <Typography className="text-white text-[14px] font-normal line-through">
            Advanced Customization +
            </Typography>
          </li>
                  </Typography>
                </td>
                  
            </tr>
            
        </tbody>
    </table> 
          {/* End  Section */}    
          
      </CardBody>
      <CardFooter className="mt-[8px] p-3">
        <Button  onClick={() => handleOpen("xxl")} variant="gradient"
          size="lg"
          color="white"
          className="pt-3 pb-3 hover:scale-[1] focus:scale-[1] active:scale-80"
          ripple={false}
          fullWidth={true} >
         Apply Now 
        </Button>
      </CardFooter>
    </Card>

             </div> 
          </div>
 
{/*    Content  Mobile Only Section  */}
  

<div className="p-[10px]  ml-[-3px] bg-white block lg:hidden">
  
<Card variant="gradient" className="bg-purple-900 
      mx-auto rounded p-[2px]">
          <CardHeader color="blue-gray" className="auto h-26">
         <img
           src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706625514/Hickter%20Files/Banners/Plans/56437440664.png"
         />
       </CardHeader>
       <CardHeader
         floated={false}
         shadow={false}
         color="transparent"
         className="m-0 mb-2 rounded-2 border-b border-white/10 pb-4 text-center"
       > 
         <Typography
           variant="h1"
           color="white"
           className="mt-6 flex justify-center gap-1 text-7xl font-normal">
           <span className="mt-2 text-[32px]"> ₹12,000  </span>{""}
           <span className="self-end text-[14px]"> / Yearly or Annually </span> 
 
         </Typography>
         <br></br> <div className="text-center" class="flex gap-10">
  <br></br> <div
     class="relative grid items-center px-2 py-1 font-sans text-xs font-bold text-green-100 uppercase rounded-md select-none whitespace-nowrap bg-green-600">
     <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
       <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-400 content-['']"></span>
     </div> 
     <span class="ml-4">20% Off </span> 
 
   </div>
   <p class="text-yellow-300 text-[22px] line-through ..."> ₹15,000 </p>
 </div> 
         <br></br>   <div
     class="p-4 relative grid select-none items-center whitespace-nowrap rounded-lg bg-orange-600 py-2 px-1 font-sans text-xs uppercase text-white">
     <span class="text-[10px]"> * Payment 40% Advance & 60% After Project Completed .   </span>
   </div><br></br>
    
   <div
     class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-teal-300 py-2 px-1 font-sans text-xs uppercase text-black">
     <span class="">*  Free Support - 24x7  | 1 Year  </span>
   </div>
       </CardHeader>
       <CardBody className="p-0">
       <table class="border-separate [border-spacing:0.75rem] w-full text-sm text-left rtl:text-right text-gray-500
 dark:text-gray-400">
        
        <tbody>
 {/*   1st  Section  */}
            <tr class="bg-transparent  dark:bg-gray-800 dark:border-gray-700">

            <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">App Development <div
    class="text-[10px] relative grid select-none items-center whitespace-nowrap rounded-lg bg-white py-1 px-2 font-sans  uppercase text-black">
    <span className="text-[10px]"> <i style={{fontSize:15}} className="text-green-500 fa-brands fa-android "></i>  Android Only  </span>
  </div>

 </Typography>
           </li>
                   </Typography>
                 </td>
  
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                    <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] mr-4 font-normal"> Website Development 
 </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>

             {/*  2nd section  */}

              <tr> 

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                     <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px]  font-normal">Digital Marketing 
 </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white  text-[14px] font-normal">
             Banner Ads Promotion 
             </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>
              
{/* 3rd section  */}
              <tr>
              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
             Video Ads Creation
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className=" flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
            Domain & Hosting   
             </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>
              
{/* 4th section  */}
              <tr>

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal ">
             Upgrade Version+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through ">
             Full Stack Feature+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
              </tr>
              
              {/* 5th Section */} 

              <tr >

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through">
             Advanced Customization +
             </Typography>
           </li>
                   </Typography>
                 </td>
  
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through">
             Company Formation+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
              </tr>
              </tbody>
              
              
              
              </table>

{/* end section mobile */}
         
       </CardBody>
       <CardFooter className="mt-[8px] p-3">
         <Button  onClick={() => handleOpen("xxl")} variant="gradient"
           size="lg"
           color="white"
           className="pt-3 pb-3 hover:scale-[1.02] focus:scale-[1.1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
          Apply Now 
         </Button>
       </CardFooter>
     </Card>
          </div>

 

        </div>
      </div>
    </div>



 </div>
 
    <Footersection/>
    </>
  )
}
 