/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import React, { Component } from 'react'
// eslint-disable-next-line no-unused-vars
import ReactPlayer from 'react-player' 


export default function Aboutusection() {
  return (
  <> 
  <section>
  <div className="mx-auto max-w-screen-xl px-4 py-2 sm:px-6  lg:px-3 lg:py-3 lg:mb-3">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
      <div className="relative h-50 overflow-hidden rounded-lg sm:h-8 lg:order-last lg:h-full">
     
      <div className='mr-5 player-wrapper'>
      <img
            className="object-contain w-full h-56 rounded shadow-lg sm:h-96"
            src="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/Banners/aboutaa.png?updatedAt=1709807091195?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=7350&amp;w=1260"
            alt=""
          />
        </div>

      </div>

      <div className="ml-6 mt-1 ">
      <h5 className="mr-5 mb-0 text-2xl font-extrabold leading-none">
          About  <a className="text-green-500" >  AasthaMediHelp </a>
 
          </h5>

        

        <p className="mt-4 text-gray-600">
      
        We provides the quality monitoring with many implementation & technical support services to our clients.
<br></br>
 We 
work together to deliver patient care.
Clinics:<br></br>

"Community Health Clinics: Your One-Stop Shop for Primary Care" - This could explain the benefits of community clinics, the services they provide, and how they play a role in preventative healthcare.
"Speciality Clinics: Getting Expert Care for Specific Needs" - This could discuss the different types of specialty clinics (e.g., cardiology, pediatrics) and how they provide focused medical care and Other Facilities .
 
        </p>
     
        <br></br><a
  class="mt-2 inline-flex items-center gap-2 rounded border border-indigo-600 bg-green-600 px-8 
  py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
  href="/Contact"
>
  <span class="text-sm font-medium"> 
Contact Us Now 
      </span>

  <svg
    class="h-5 w-5 rtl:rotate-180"
    xmlns="https://ik.imagekit.io/hickter/Hickter_Projects/MSWS/OTHERS/socialabout.png?updatedAt=1708347173488"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </svg>
</a>
 
<br></br> </div>
    </div> 
  </div>

  <div className="px-4 py-2 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:mb-[10px]">
      <div className="grid gap-10 lg:grid-cols-2">
      <div>
          <img
            className="object-contain w-full h-50 rounded shadow-lg sm:h-96"
            src="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/Banners/Mission.png?updatedAt=1709807408734?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
            alt=""
          />
        </div>
        <div className="lg:pr-10">
          <a
            href="/"
            aria-label="Go Home"
            title="Logo"
            className="inline-block mb-5"
          >
           
          </a>
          <h5 className="ml-0 mb-4 text-2xl font-extrabold leading-none">
          Our   <a className="text-green-500" >  Mission </a>
 
          </h5>
         <b> Improve Patient Care:  </b> This is the overarching goal. Consultants use their specialized knowledge and experience 
         <br></br>
         <b> Enhance Healthcare Delivery: </b> Consultants can help hospitals, clinics, and other healthcare organizations function more efficiently and effectively.  
<br></br>
<b> Provide Expert Guidance: </b>  Medical consultants offer specialized knowledge in a particular medical field. 
<br></br>
<b> Promote Evidence-Based Medicine:</b>  Medical consultants should base their recommendations on current scientific research and best practices.  
<br></br>
<b> Advocate for Quality and Cost-Effectiveness:</b>   In some cases, consultants might be brought in to help control healthcare costs while maintaining quality care.  
          <hr className="mb-5 border-gray-300" />
          
        </div>
       
      </div>
    </div>

</section>
  </>
  )
}

 