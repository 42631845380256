
import { useState, useEffect } from "react";
 /* eslint-disable no-unused-vars */
import React from 'react'
 import Cardsk from '../Components/Cardsk'
import Skeletonui from '../Components/Skeletonui'
import Navbarsection from "../views/Home/Navbarsection";
import Footersection from "../views/Home/Footersection";
import Iframe from 'react-iframe'

function Loginaccount() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <><Navbarsection /> <div className="flex justify-center items-center h-screen">
      {loading ? <Skeletonui /> : <Iframe className="mt-[1px]" url="https://www.mahakalsocialwelfaresociety.thedigitaldynamo.in/login/"
        width="100%"
        height="460px" 
        display="block"
        position="relative"
        frameBorder="0"
        scrolling="yes"
        overflow="hidden"
        z-index="999999" />}
    </div> <Footersection />
    
    </>
  );
}

export default Loginaccount;
  


