/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
 


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect } from "react"
import Glide from "@glidejs/glide"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2000, min: 1000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 2000, min: 900 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items:1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}
export default function Plans() {
  return (
   <>
      
  <section className="mt-[30px]">
  <div class="mx-auto">
      <div  class="mb-[3px] text-gray-600 body-font  ">
      <h3 class="text-center text-2xl font-bold sm:text-3xl"> Our Plans   </h3>
        </div>
      </div>

      <Carousel responsive={responsive}  className="z-[0] overscroll-x-none P-3" >
  <div className="p-6"> 

{/* Startup Plan Start section  */}
<Card variant="gradient" className="bg-purple-900 
      mx-auto rounded p-[2px]">
          <CardHeader color="blue-gray" className="auto h-26">
         <img
           src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706625514/Hickter%20Files/Banners/Plans/56437440664.png"
         />
       </CardHeader>
       <CardHeader
         floated={false}
         shadow={false}
         color="transparent"
         className="m-0 mb-2 rounded-2 border-b border-white/10 pb-4 text-center"
       > 
         <Typography
           variant="h1"
           color="white"
           className="mt-6 flex justify-center gap-1 text-7xl font-normal">
           <span className="mt-2 text-[32px]">₹12,000  </span>{""}
           <span className="self-end text-[14px]">   / Yearly or Annually  </span> 
 
         </Typography>
         <br></br> <div className="text-center" class="flex gap-10">
  <br></br> <div
     class="relative grid items-center px-1 py-1 font-sans text-xs font-bold text-green-100 uppercase rounded-md select-none whitespace-nowrap bg-green-600">
     <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
       <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-400 content-['']"></span>
     </div> 
     <span class="ml-4">20% Off </span> 
 
   </div>
   <p class="text-yellow-300 text-[22px] line-through ..."> ₹15,000 </p>
 </div> 
         <br></br>
       
    
         <div
     class="p-4 relative grid select-none items-center whitespace-nowrap rounded-lg bg-orange-600 py-2 px-1 font-sans text-xs uppercase text-white">
     <span class="text-[10px]"> * Payment 40% Advance & 60% After Project Completed .   </span>
   </div><br></br>
    
   <div
     class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-teal-300 py-2 px-1 font-sans text-xs uppercase text-black">
     <span class="">*  Free Support - 24x7  | 1 Year  </span>
   </div>
       </CardHeader>
       <CardBody className="p-0">
       <table class="border-separate [border-spacing:0.75rem] w-full text-sm text-left rtl:text-right text-gray-500
 dark:text-gray-400">
        
        <tbody>
 {/*   1st  Section  */}
            <tr class="bg-transparent  dark:bg-gray-800 dark:border-gray-700">

            <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal">
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">App Development <div
    class="text-[10px] relative grid select-none items-center whitespace-nowrap rounded-lg bg-white py-1 px-2 font-sans  uppercase text-black">
    <span className="text-[9px]"> <i style={{fontSize:10}} className="text-green-500 fa-brands fa-android "></i>  Android Only  </span>
  </div>

 </Typography>
           </li>
                   </Typography>
                 </td>
  
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                    <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] mr-4 font-normal"> Website Development 
 </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>

             {/*  2nd section  */}

              <tr> 

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                     <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px]  font-normal">Digital Marketing 
 </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white  text-[14px] font-normal">
             Banner Ads    
             </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>
              
{/* 3rd section  */}
              <tr>
              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
             Video Ads Creation
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className=" flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
            Domain & Hosting   
             </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>
              
{/* 4th section  */}
              <tr>

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal ">
             Upgrade Version+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through ">
             Full Stack Feature+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
              </tr>
              
              {/* 5th Section */} 

              <tr >

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through">
             Advanced Customization +
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through">
             Company Formation+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
              </tr>
              </tbody>
              
              
              
              </table>

{/* end section mobile */}
         
       </CardBody>
       <CardFooter className="mt-[8px] p-3">
       <a href="/Startup_plan_hickter">  <Button
           size="lg"
           color="white"
           className="pt-3 pb-3 hover:scale-[1.02] focus:scale-[1.1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
          View & Apply
         </Button></a>
       </CardFooter>
     </Card>
     
 
 </div>
{/* End First Plan Startup Plan */}  
 
{/* Business Started  Plan */} 
 <div className="p-6">
  
 <Card variant="gradient" className="bg-purple-900 
      mx-auto rounded p-[2px]">
          <CardHeader color="blue-gray" className="auto h-26">
         <img
           src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706625535/Hickter%20Files/Banners/Plans/47175405222.png"
         />
       </CardHeader>
       <CardHeader
         floated={false}
         shadow={false}
         color="transparent"
         className="m-0 mb-2 rounded-2 border-b border-white/10 pb-4 text-center"
       > 
         <Typography
           variant="h1"
           color="white"
           className="mt-6 flex justify-center gap-1 text-7xl font-normal">
           <span className="mt-2 text-[32px]">₹24,000 </span>{""}
           <span className="self-end text-[14px]"> / Yearly or Annually </span> 
 
         </Typography>
         <br></br> <div className="text-center" class="flex gap-10">
  <br></br> <div
     class="relative grid items-center px-2 py-1 font-sans text-xs font-bold text-green-100 uppercase rounded-md select-none whitespace-nowrap bg-green-600">
     <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
       <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-400 content-['']"></span>
     </div> 
     <span class="ml-4"> 20% Off </span> 
 
   </div>
   <p class="text-yellow-300 text-[22px] line-through ..."> ₹30,000</p>
 </div> 
         <br></br>
       
    
         <div
     class="p-4 relative grid select-none items-center whitespace-nowrap rounded-lg bg-orange-600 py-2 px-1 font-sans text-xs uppercase text-white">
     <span class="text-[10px]"> * Payment 40% Advance & 60% After Project Completed .   </span>
   </div><br></br>
    
   <div
     class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-teal-300 py-2 px-1 font-sans text-xs uppercase text-black">
     <span class="">*  Free Support - 24x7  | 1 Year  </span>
   </div>
       </CardHeader>
       <CardBody className="p-0">
       <table class="border-separate [border-spacing:0.75rem] w-full text-sm text-left rtl:text-right text-gray-500
 dark:text-gray-400">
        
        <tbody>
 {/*   1st  Section  */}
            <tr class="bg-transparent  dark:bg-gray-800 dark:border-gray-700">

            <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">App Development <div
    class="text-[10px] relative grid select-none items-center whitespace-nowrap rounded-lg bg-white py-1 px-1 font-sans  uppercase text-black">
    <span className="text-[9px]"> <i style={{fontSize:10}} className="text-green-500 fa-brands fa-android "></i>  <i style={{fontSize:10}} className="fa-brands text-blue-700 fa-app-store-ios ml-1 p-1"></i> Android & iOS  </span>
  </div>

 </Typography>
           </li>
                   </Typography>
                 </td>
  
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                    <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] mr-4 font-normal"> Website Development 
 </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>

             {/*  2nd section  */}

              <tr> 

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                     <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px]  font-normal">Digital Marketing 
 </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white  text-[14px] font-normal">
             Banner Ads    
             </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>
              
{/* 3rd section  */}
              <tr>
              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
             Video Ads Creation
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className=" flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
            Domain & Hosting   
             </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>
              
{/* 4th section  */}
              <tr>

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal ">
             Upgrade Version+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through ">
             Full Stack Feature+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
              </tr>
              
              {/* 5th Section */} 

              <tr >

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through">
             Advanced Customization +
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through">
             Company Formation+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
              </tr>
              </tbody>
              
              
              
              </table>

{/* end section mobile */}
         
       </CardBody>
       <CardFooter className="mt-[8px] p-3">
         <a href="/Business_plan_hickter"> <Button
           size="lg"
           color="white"
           className="pt-3 pb-3 hover:scale-[1.02] focus:scale-[1.1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
          View & Apply 
         </Button></a>
       </CardFooter>
     </Card>
</div>

{/* End Business Plan Hickter */}

<div className="p-5">
  
{/* Start Corporate  Plan Hickter */}
<Card variant="gradient" className="bg-purple-900 
      mx-auto rounded p-[2px]">
          <CardHeader color="blue-gray" className="auto h-26">
         <img
           src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706625544/Hickter%20Files/Banners/Plans/3.png"
         />
       </CardHeader>
       <CardHeader
         floated={false}
         shadow={false}
         color="transparent"
         className="m-0 mb-2 rounded-2 border-b border-white/10 pb-4 text-center"
       > 
         <Typography
           variant="h1"
           color="white"
           className="mt-6 flex justify-center gap-1 text-7xl font-normal">
           <span className="mt-2 text-[32px]">₹64,000
  </span>{""}
           <span className="self-end text-[14px]">  / Yearly or Annually  </span> 
 
         </Typography>
         <br></br> <div className="text-center" class="flex gap-10">
  <br></br> <div
     class="relative grid items-center px-2 py-1 font-sans text-xs font-bold text-green-100 uppercase rounded-md select-none whitespace-nowrap bg-green-600">
     <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
       <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-400 content-['']"></span>
     </div> 
     <span class="ml-4">20% Off </span> 
 
   </div>
   <p class="text-yellow-300 text-[22px] line-through ..."> ₹80,000 </p>
 </div> 
         <br></br>
        
         <div
     class="p-4 relative grid select-none items-center whitespace-nowrap rounded-lg bg-orange-600 py-2 px-1 font-sans text-xs uppercase text-white">
     <span class="text-[10px]"> * Payment 40% Advance & 60% After Project Completed .   </span>
   </div><br></br>
    
   <div
     class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-teal-300 py-2 px-1 font-sans text-xs uppercase text-black">
     <span class="">*  Free Support - 24x7  | 1 Year  </span>
   </div>
       </CardHeader>
       <CardBody className="p-0">
       <table class="border-separate [border-spacing:0.75rem] w-full text-sm text-left rtl:text-right text-gray-500
 dark:text-gray-400">
        
        <tbody>
 {/*   1st  Section  */}
            <tr class="bg-transparent  dark:bg-gray-800 dark:border-gray-700">

            <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">App Development <div
    class="text-[10px] relative grid select-none items-center whitespace-nowrap rounded-lg bg-white py-1 px-2 font-sans  uppercase text-black">
    <span className="text-[9px]"> <i style={{fontSize:10}} className="text-green-500 fa-brands fa-android "></i>  <i style={{fontSize:10}} className="fa-brands text-blue-700 fa-app-store-ios ml-1 p-1"></i> Android & iOS  </span>
  </div>

 </Typography>
           </li>
                   </Typography>
                 </td>
  
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                    <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] mr-4 font-normal"> Website Development 
 </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>

             {/*  2nd section  */}

              <tr> 

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                     <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px]  font-normal">Digital Marketing 
 </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white  text-[14px] font-normal">
             Banner Ads    
             </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>
              
{/* 3rd section  */}
              <tr>
              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
             Video Ads Creation
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className=" flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
            Domain & Hosting   
             </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>
              
{/* 4th section  */}
              <tr>

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal ">
             Upgrade Version+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal ">
             Full Stack Feature+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
              </tr>
              
              {/* 5th Section */} 

              <tr > 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
             Company Formation+ 
             </Typography>
           </li>
                   </Typography>
                 </td>

                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through">
             Advanced Customization +
             </Typography>
           </li>
                   </Typography>
                 </td>
              </tr>
              </tbody>
               
              
              </table>

{/* end section mobile */}
         
       </CardBody>
       <CardFooter className="mt-[8px] p-3">
       <a href="/Corporate_plan_hickter"> <Button
           size="lg"
           color="white"
           className="pt-3 pb-3 hover:scale-[1.02] focus:scale-[1.1] active:scale-80"
           ripple={false}
           fullWidth={true} >
          View & Apply
         </Button></a>
       </CardFooter>
     </Card>
{/* End Corporate Plan Hickter  */}
</div>
 
<div className="p-5">
  
  
  {/* Start  Custom  Plan Hickter  */}
  <Card variant="gradient" className="bg-purple-900 
      mx-auto rounded p-[2px]">
          <CardHeader color="blue-gray" className="auto h-26">
         <img
           src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706625539/Hickter%20Files/Banners/Plans/4.png"
         />
       </CardHeader>
       <CardHeader
         floated={false}
         shadow={false}
         color="transparent"
         className="m-0 mb-2 rounded-2 border-b border-white/10 pb-4 text-center"
       > 
         <Typography
           variant="h1"
           color="white"
           className="mt-6 flex justify-center gap-1 text-7xl font-normal">
           <span className="mt-2 text-[32px]">₹1,20,000
  </span>{""}
           <span className="self-end text-[14px]">  / Yearly or Annually  </span> 
 
         </Typography>
         <br></br> <div className="text-center" class="flex gap-10">
  <br></br> <div
     class="relative grid items-center px-2 py-1 font-sans text-xs font-bold text-green-100 uppercase rounded-md select-none whitespace-nowrap bg-green-600">
     <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
       <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-400 content-['']"></span>
     </div> 
     <span class="ml-4">20% Off </span> 
 
   </div>
   <p class="text-yellow-300 text-[22px] line-through ..."> ₹1,50,000 </p>
 </div> 
         <br></br>
        
         <div
     class="p-4 relative grid select-none items-center whitespace-nowrap rounded-lg bg-orange-600 py-2 px-1 font-sans text-xs uppercase text-white">
     <span class="text-[10px]"> * Payment 40% Advance & 60% After Project Completed .   </span>
   </div><br></br>
    
   <div
     class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-teal-300 py-2 px-1 font-sans text-xs uppercase text-black">
     <span class="">*  Free Support - 24x7  | 1 Year  </span>
   </div>
       </CardHeader>
       <CardBody className="p-0">
       <table class="border-separate [border-spacing:0.75rem] w-full text-sm text-left rtl:text-right text-gray-500
 dark:text-gray-400">
        
        <tbody>
 {/*   1st  Section  */}
            <tr class="bg-transparent  dark:bg-gray-800 dark:border-gray-700">

            <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">App Development <div
    class="text-[10px] relative grid select-none items-center whitespace-nowrap rounded-lg bg-white py-1 px-1 font-sans  uppercase text-black">
    <span className="text-[9px]"> <i style={{fontSize:10}} className="text-green-500 fa-brands fa-android "></i>  <i style={{fontSize:10}} className="fa-brands text-blue-700 fa-app-store-ios ml-1 p-1"></i> Android & iOS  </span>
  </div>

 </Typography>
           </li>
                   </Typography>
                 </td>
  
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                    <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] mr-4 font-normal"> Website Development 
 </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>

             {/*  2nd section  */}

              <tr> 

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                     <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px]  font-normal">Digital Marketing 
 </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white  text-[14px] font-normal">
             Banner Ads    
             </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>
              
{/* 3rd section  */}
              <tr>
              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
             Video Ads Creation
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className=" flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
            Domain & Hosting   
             </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>
              
{/* 4th section  */}
              <tr>

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal ">
             Upgrade Version+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal ">
             Full Stack Feature+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
              </tr>
              
              {/* 5th Section */} 

              <tr > 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
             Company Formation+ 
             </Typography>
           </li>
                   </Typography>
                 </td>

                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
             Advanced Customization +
             </Typography>
           </li>
                   </Typography>
                 </td>
              </tr>
              </tbody>
               
              
              </table>

{/* end custom mobile */}
         
       </CardBody>
       <CardFooter className="mt-[8px] p-3">
       <a href="/Custom_plan_hickter">   <Button
           size="lg"
           color="white"
           className="pt-3 pb-3 hover:scale-[1.02] focus:scale-[1.1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
          View & Apply
         </Button></a>
       </CardFooter>
     </Card>
</div>

  
  
</Carousel>

  </section>

  
   
   </>
  )
}
 