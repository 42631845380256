import { useState, useEffect } from "react";
 /* eslint-disable no-unused-vars */
import React from 'react'
 import Cardsk from '../Components/Cardsk'
import Skeletonui from '../Components/Skeletonui'
import Navbarsection from "../views/Home/Navbarsection";
import Footersection from "../views/Home/Footersection";

function Accounts() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <><Navbarsection /><div className="flex justify-center items-center h-screen">
      {loading ? <Skeletonui /> : <Cardsk />}
    </div><Footersection />
    
    
    
    
    
    </>
  );
}

export default Accounts;
  