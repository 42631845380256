/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../Home/Navbarsection'
import Footersection from '../Home/Footersection'
import { useState, useEffect } from "react";

   import Iframe from 'react-iframe'
import Skeletonui from '../../Components/Skeletonui';
export default function Quickbooking() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 11000);
  }, []);
  
  return (
    <>
   
<Navbarsection />
<div className="relative flex flex-col-reverse py-16 lg:py-0 lg:flex-col">
      <div className="w-full max-w-xl px-4 mx-auto md:px-0 lg:px-2 lg:py-1 lg:max-w-screen-xl">
        <div className="mb-0 lg:max-w-lg lg:pr-8 xl:pr-6">
         
       
      <div className="hidden md:block mt-4 rounded-lg p-[1px] shadow-lg lg:col-span-3 lg:p-1">

       
      {loading ? <Skeletonui />  :  <Iframe url="https://hickter.in/aasthamedihelp/quick-booking.html"
         width="730px"
         height="700px" 
         display="block"
         position=""/>   }

        {/*  Dextop Only  */}
        <br></br>
 
       </div>
{/* Mobile Only  */}
       <div className="block lg:hidden mt-4 rounded-lg p-[1px] shadow-lg lg:col-span-3 lg:p-1"> 
        
        
      
     {loading ? <Skeletonui /> :     <Iframe url="https://hickter.in/aasthamedihelp/quick-booking.html"
         width="100%"
         height="700px" 
         display="block"
         position="relative"/>}
  
 
       </div>
         
        </div>
      </div>
      <div className="inset-y-0 top-0 right-0 w-full max-w-xl px-0 mx-auto mb-3 md:px-0 lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/3 lg:max-w-full lg:absolute xl:px-0">
        <img
          className="p-4 object-contain  rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:w-[403px] lg:h-[443px]"
          src="https://ik.imagekit.io/hickter/Hickter_Projects/Aasthamedihelp/Media/Banners/quick-booking-aa.png?updatedAt=1709825532389"
          alt=""
        />
      </div>
    </div>
<Footersection />
    </>
  )
}


 