/* eslint-disable react/no-unknown-property */

import All_portfolio_hickter from "../Portfolio/All_portfolio_hickter"
import Footersection from "../views/Home/Footersection"
import Navbarsection from "../views/Home/Navbarsection"
  
export default function Our_Portfolio() {
  return (
<>  
<Navbarsection/>
<All_portfolio_hickter/> 
<Footersection/>
</>
  )
}
 