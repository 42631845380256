/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'
import Plans from '../views/Home/Plans'
import Pricingsection from '../views/Home/Pricingsection'

export default function Plans_and_Pricing() {
  return (
    <>
    <Navbarsection/>
 <Plans/>
 <Pricingsection/>
    <Footersection/>
    </>
  )
}
