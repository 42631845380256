/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import React from "react"
import ReactDom from "react-dom/client" 
 import About from "./Pages/About.jsx"; 
 import Blog from "./Pages/Blog.jsx"; 
 import Location from "./Pages/Location.jsx";
 import Offer from "./Pages/Offer.jsx"; 
 import Search from "./Pages/Search.jsx"; 
 import Contact from "./Pages/Contact.jsx";
 import Deliveryagent from "./Pages/Deliveryagent.jsx";
 import StoreMerchant from "./Pages/StoreMerchant.jsx"; 
 import Aboutusection from "./views/About/Aboutusection.jsx"; 
 import Jobs from "./Pages/Jobs.jsx"; 
 import Support from "./Pages/Support.jsx";
 import News from "./Pages/News.jsx";
 import Terms from "./Pages/Terms.jsx";
 import Privacy from "./Pages/Privacy.jsx";
 import Home from "./Pages/Home.jsx"; 
import Agentsections from "./views/Join/Agentsections.jsx";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Merchantsections from "./views/Join/Merchantsections.jsx";
import Ourpricing from "./Pages/Ourpricing.jsx";
import Blog1 from "./views/Blogs/Blog1.jsx";
import Blog2 from "./views/Blogs/Blog2.jsx";
import Blog3 from "./views/Blogs/Blog3.jsx";
import Pricingsection from "./views/Home/Pricingsection.jsx";
import Our_services_hickter from "./Pages/Our_services_hickter.jsx";
import Our_plan_hickter from "./Pages/Our_plan_hickter.jsx";
import Our_team_hickter from "./Pages/Our_team_hickter.jsx";
import Business_plan_hickter from "./Plans/Business_plan_hickter.jsx";
import Corporate_plan_hickter from "./Plans/Corporate_plan_hickter.jsx";
import Custom_plan_hickter from "./Plans/Custom_plan_hickter.jsx";
import App_development_hickter from "./Services/App_development_hickter.jsx";
import Website_development_hickter from "./Services/Website_development_hickter.jsx";
import Digital_marketing_hickter from "./Services/Digital_marketing_hickter.jsx";
import Banner_ads_hickter from "./Services/Banner_ads_hickter.jsx";
import Video_ads_hickter from "./Services/Video_ads_hickter.jsx";
import Our_Portfolio from "./Pages/Our_Portfolio.jsx";
import All_portfolio_hickter from "./Portfolio/All_portfolio_hickter.jsx";
import App_demo from "./Demo/App_demo.jsx";
import Website_demo from "./Demo/Website_demo.jsx"; 
import Portfolio_section_hickter from "./Portfolio/Portfolio_section_hickter.jsx";
import Startup_plan_hickter from "./Plans/Startup_plan_hickter.jsx";
import Demo_hickter from "./Demo/Demo_hickter.jsx";
import AllPricing from "./Pricing/AllPricing.jsx";
import AllPlans from "./Plans/AllPlans.jsx";
import AllServices from "./Services/AllServices.jsx";
import Teamsection from "./views/Teams/Teamsection.jsx";
import Faq from "./Pages/Faq.jsx";
import Plans_and_Pricing from "./Pages/Plans_and_Pricing.jsx";
import Logo_design_hickter from "./Services/logo_design_hickter.jsx";
 import Skeletonui from "./Components/Skeletonui.jsx";
import Cardsk from "./Components/Cardsk.jsx";
import Loginaccount from "./Myaccount/Loginaccount.jsx";
import Accounts from "./Myaccount/Accounts.jsx";
import Mydownload from "./Myaccount/Mydownload.jsx";
import Myplanorder from "./Myaccount/Myplanorder.jsx";
import Signupaccount from "./Myaccount/Signupaccount.jsx";
import Profileaccount from "./Myaccount/Profileaccount.jsx";
import Booking from "./Pages/Booking.jsx";
import Whyus from "./views/Home/Whyus.jsx";
import Doctorbooking from "./views/Booking/Doctorbooking.jsx";
import Quickbooking from "./views/Booking/Quickbooking.jsx";
 
const router = createBrowserRouter([ 
  {
    path: '/',
    element: <Home /> 
  }, 
  { 
    path: '/About',
    element: <About /> 
  }, 
  { 
    path: '/Home',
    element: <Home /> 
  }, 
  
  { 
    path: '/Faq',
    element: <Faq /> 
  }, 
  { 
    path: '/Blog',
    element: <Blog /> 
  }, 
  { 
    path: '/Location',
    element: <Location /> 
  }, 
  { 
    path: '/Offer',
    element: <Offer /> 
  }, 
  { 
    path: '/Search',
    element: <Search /> 
  }, 
  { 
    path: '/Aboutusection',
    element: <Aboutusection /> 
  }, 
  { 
    path: '/Contact',
    element: <Contact /> 
  }, 
  { 
    path: '/Plans_and_Pricing',
    element: <Plans_and_Pricing /> 
  },
  { 
    path: '/StoreMerchant',
    element: <StoreMerchant /> 
  }, 
  { 
    path: '/Deliveryagent',
    element: <Deliveryagent /> 
  } ,
  { 
    path: '/Jobs',
    element: <Jobs /> 
  } ,
  { 
    path: '/Support',
    element: <Support /> 
  } ,
  { 
    path: '/News',
    element: <News /> 
  } ,
  { 
    path: '/Terms',
    element: <Terms /> 
  } ,
  { 
    path: '/Privacy',
    element: <Privacy /> 
  } ,
  { 
    path: '/Agentsections',
    element: <Agentsections /> 
  } ,
  { 
    path: '/Merchantsections',
    element: <Merchantsections /> 
  } ,
  { 
    path: '/Blog1',
    element: <Blog1 /> 
  } ,
  { 
    path: '/Blog2',
    element: <Blog2 /> 
  } ,
  { 
    path: '/Ourpricing',
    element: <Ourpricing /> 
  } ,
  { 
    path: '/Pricingsection',
    element: <Pricingsection /> 
  } ,
  { 
    path: '/Blog3',
    element: <Blog3 /> 
  } ,
  { 
    path: '/Our_services_hickter',
    element: <Our_services_hickter/> 
  } ,
  { 
    path: '/Our_plan_hickter',
    element: <Our_plan_hickter/> 
  } ,
  { 
    path: '/Startup_plan_hickter',
    element: <Startup_plan_hickter/> 
  } ,
  
  { 
    path: '/Business_plan_hickter',
    element: <Business_plan_hickter/> 
  } ,
  { 
    path: '/Corporate_plan_hickter',
    element: <Corporate_plan_hickter/> 
  } ,
  { 
    path: '/Custom_plan_hickter',
    element: <Custom_plan_hickter/> 
  } ,
  { 
    path: '/App_development_hickter',
    element: <App_development_hickter/> 
  } ,
  { 
    path: '/Website_development_hickter',
    element: <Website_development_hickter/> 
  } ,
  { 
    path: '/Digital_marketing_hickter',
    element: <Digital_marketing_hickter/> 
  } ,
  { 
    path: '/Banner_ads_hickter',
    element: <Banner_ads_hickter/> 
  } ,
  { 
    path: '/Video_ads_hickter',
    element: <Video_ads_hickter/> 
  } ,
  { 
    path: '/Our_Portfolio',
    element: <Our_Portfolio/> 
  } ,  
  { 
    path: '/All_portfolio_hickter',
    element: <All_portfolio_hickter/> 
  } ,  
  { 
    path: '/Portfolio_section_hickter',
    element: <Portfolio_section_hickter/> 
  } , 
  { 
    path: '/App_demo',
    element: <App_demo/> 
  } , 
  { 
    path: '/Demo_hickter',
    element: <Demo_hickter/> 
  } , 
  { 
    path: '/Logo_design_hickter',
    element: <Logo_design_hickter/>
  } , 
  { 
    path: '/Website_demo',
    element: <Website_demo/> 
  } ,  
  { 
    path: '/AllPlans',
    element: <AllPlans/> 
  } , 
  { 
    path: '/Teamsection',
    element: <Teamsection/> 
  } ,
  { 
    path: '/AllServices',
    element: <AllServices/> 
  } ,
  { 
    path: '/AllPricing',
    element: <AllPricing/> 
  } ,
  { 
    path: '/Our_team_hickter',
    element: <Our_team_hickter/> 
  } ,
  { 
    path: '/Cardsk',
    element: <Cardsk/> 
  } ,
  { 
    path: '/Loginaccount',
    element: <Loginaccount/> 
  } ,
  { 
    path: '/Accounts',
    element: <Accounts/> 
  } ,
  { 
    path: '/Mydownload',
    element: <Mydownload/> 
  } ,
  { 
    path: '/Myplanorder',
    element: <Myplanorder/> 
  } ,
  { 
    path: '/Whyus',
    element: <Whyus/> 
  } ,
  { 
    path: '/Signupaccount',
    element: <Signupaccount/> 
  } ,
  { 
    path: '/Profileaccount',
    element: <Profileaccount/> 
  } , 
  { 
    path: '/Quickbooking',
    element: <Quickbooking/> 
  } ,
  { 
    path: '/Doctorbooking',
    element: <Doctorbooking/> 
  } ,
  { 
    path: '/Booking',
    element: <Booking/> 
  } , 
  { 
    path: '/Skeletonui',
    element: <Skeletonui/> 
  }  
]);
  
ReactDom.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  <RouterProvider router={router} />
  </React.StrictMode>
)

export default router;